import React, { useState } from 'react'
import './CrearInmobiliariaModal.css'
import Exit from '../../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'
import MainButton from '../../MainButton/MainButton';
import CustomTextInput from '../../CustomTextInput/CustomTextInput';
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/success.json'
import { actionsCreateItem } from '../../../actions';

const CrearInmobiliariaModal = (props: any) => {

    const [nombreInmobiliaria, setNombreInmobiliaria] = useState<string>('')
    const [direccion, setDireccion] = useState<string>('')
    const [emailInmobiliaria, setEmailInmobiliaria] = useState<string>('')
    const [telefono, setTelefono] = useState<string>('')

    const [apellidoEncargado, setApellidoEncargado] = useState<string>('')
    const [nombreEncargado, setNombreEncargado] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidName = (e: string) => {
        if(e?.length > 0) {
            if(!(/^\s/.test(e))) {
                return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
            }
        } return false
    };

    const isDisabled = () => {
        return (!validateEmail(emailInmobiliaria) || direccion === '' || !isValidName(nombreInmobiliaria) || telefono === '' || !isValidName(apellidoEncargado) || !isValidName(nombreEncargado))
    }

    return (
        <React.Fragment>
            {success ? 
                <div className='crearInmobiliariaModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => {
                            props.onDismiss()
                            window.location.reload();
                        }}
                    />
                     <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: sentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        width={223}
                        isStopped={false}
                        isPaused={false}
                        style={{margin: 0}}
                    />
                    <p className='crearInmobiliariaModal-container-title bold'>¡Excelente!</p>
                    <p className='crearInmobiliariaModal-container-subTitle regular large'>La inmobiliaria ha sido creada exitosamente.<br/>Podrás visualizarla en el listado de inmobiliarias.</p>
                    <div className='crearInmobiliariaModal-container-buttonWrap'>
                        <MainButton
                            text='ACEPTAR'
                            onPress={() => {
                                props.onDismiss()
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>
                :
                <div className='crearInmobiliariaModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => props.onDismiss()}
                    />
                    <p className='crearInmobiliariaModal-container-title bold'>Crear inmobiliaria</p>
                    <p className='crearInmobiliariaModal-container-subTitle regular large'>Ingresá los siguientes datos para dar de alta una nueva inmobiliaria.</p>
                    <p className='crearInmobiliariaModal-container-inputTitle bold'>Datos de la inmobiliaria</p>
                    <div className='crearInmobiliariaModal-container-inputList'>
                        <CustomTextInput
                            label='NOMBRE DE LA INMOBILIARIA'
                            onChange={(e: any) => {
                                setNombreInmobiliaria(e.target.value)
                                setError('')
                            }}
                            value={nombreInmobiliaria}
                            success={isValidName(nombreInmobiliaria)}
                            error={nombreInmobiliaria?.length > 0 && !isValidName(nombreInmobiliaria)}
                            required
                            errorMessage={error}
                            placeholder='Ingresá el nombre de la inmobiliaria'
                        />
                        <CustomTextInput
                            label='DIRECCIÓN'
                            onChange={(e: any) => setDireccion(e.target.value)}
                            value={direccion}
                            success={direccion.length > 0}
                            required
                            placeholder='Calle, número, localidad, provincia'
                        />
                        <CustomTextInput
                            label='E-MAIL'
                            placeholder='nombre@ejemplo.com'
                            onChange={(e: any) => setEmailInmobiliaria(e.target.value)}
                            value={emailInmobiliaria}
                            error={(emailInmobiliaria.length > 0 && !validateEmail(emailInmobiliaria)) || error}
                            errorMessage={error}
                            success={validateEmail(emailInmobiliaria)}
                            required
                        />
                        <CustomTextInput
                            label='TELÉFONO'
                            onChange={(e: any) => setTelefono(e.target.value.replace(/[^0-9]+/g, ''))}
                            value={telefono}
                            success={telefono.length > 0}
                            required
                            placeholder='Sólo números'
                        />
                    </div>
                    <p className='crearInmobiliariaModal-container-inputTitle bold'>Datos del encargado</p>
                    <div className='crearInmobiliariaModal-container-inputList'>
                        <CustomTextInput
                            label='APELLIDO'
                            onChange={(e: any) => setApellidoEncargado(e.target.value)}
                            value={apellidoEncargado}
                            success={isValidName(apellidoEncargado)}
                            error={apellidoEncargado?.length > 0 && !isValidName(apellidoEncargado)}
                            required
                        />
                        <CustomTextInput
                            label='NOMBRE'
                            onChange={(e: any) => setNombreEncargado(e.target.value)}
                            value={nombreEncargado}
                            success={isValidName(nombreEncargado)}
                            error={nombreEncargado?.length > 0 && !isValidName(nombreEncargado)}
                            required
                        />
                    </div>
                    <div className='crearInmobiliariaModal-container-buttonList'>
                        <MainButton
                            text='CANCELAR'
                            sinFondo
                            onPress={() => props.onDismiss()}
                        />
                        <MainButton
                            text={loading ? 'CREANDO...' : 'FINALIZAR'}
                            disabled={isDisabled()}
                            onPress={() => {
                                const data = {
                                    "name": nombreInmobiliaria,
                                    "lastName": ' ',
                                    "branchName": nombreEncargado + ' ' + apellidoEncargado,
                                    "userName": emailInmobiliaria,
                                    "addressFloor": '_',
                                    "addressFormatGM": '_',
                                    "addressPostalCode": '_',
                                    "addressStreet": direccion,
                                    "birthDate": '2023-07-07T13:58:16.958Z',
                                    "civilStatusId": 0,
                                    "colonia": '_',
                                    "cuil": '_',
                                    "delegacion": '_',
                                    "documentId": '1',
                                    "documentNumber": '_',
                                    "localidad": 0,
                                    "motherLastName": '_',
                                    "nacionalityId": '2',
                                    "phone": telefono,
                                    "provincia": 0,
                                    "sexId": 'male',
                                    "roles": [6],
                                    "tipoVendedor": '_'
                                }
                                actionsCreateItem(data, '/v2/user/fast_user/39', setLoading, setSuccess, setError)
                            }}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    );

}

export default CrearInmobiliariaModal;