import React, { useEffect, useState } from 'react'
import { 
    getPolizaData,
    getCheckoutData
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasData.css'
import Moment from 'moment/moment'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import { estadoCoberturaList } from '../../../util/EstadoCoberturaListColors'

const PolizasData = (props: any) => {

    const [polizaData, setPolizaData] = useState<any>(null)
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [packageData, setPackageData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getPolizaData(props.polizaId, setPolizaData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if(polizaData) {
            getCheckoutData(polizaData.checkoutId, setCheckoutData, setLoading)
            props.setPolicyUrl(polizaData?.url)
        }
    }, [polizaData])

    useEffect(() => {
        if(checkoutData) {
            setPackageData(checkoutData?.offer?.packages?.filter((a: any) => a?.id === checkoutData?.offer?.currentPackage)[0])
            props.setCheckoutData(checkoutData)
        }
    }, [checkoutData])

    const getFormaDePago = (type: string) => {
        switch(type) {
            case 'semi_simple_creditcard':
                return 'Tarjeta de crédito';
            case 'cbu':
                return 'CBU';
            default:
                return 'Efectivo';
        }
    }

    const extractErrorMessage = (message: any, keyword: any) => {
        const startIndex = message.indexOf(keyword);
        if (startIndex !== -1) {
          return message.substring(startIndex + keyword.length).trim();
        }
        return "Error";
      };

    return (
        <div className='polizasData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasData-container-data'>
                    <div className='polizasData-container-data-section'>
                        <CustomTextInput
                            label='PÓLIZA'
                            value={polizaData?.number}
                            disabled
                            color={estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}
                        />
                        <CustomTextInput
                            label='ESTADO'
                            value={estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}
                            disabled
                            color={estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}
                            tooltipIcon={checkoutData?.status === 'FAILED_EMISSION' ? 'El motivo es: ' + extractErrorMessage(checkoutData?.config?.beautifyError, 'Error al emitir:') : null}
                        />
                        <CustomTextInput
                            label='COBERTURA'
                            value={packageData?.description}
                            disabled
                        />
                        <CustomTextInput
                            label='ASEGURA'
                            value={polizaData?.productPolicyDTO?.insurerName}
                            disabled
                        />
                        <CustomTextInput
                            label='INICIO DE VIGENCIA'
                            value={Moment(polizaData?.fechaDesde).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='FIN DE VIGENCIA'
                            value={Moment(polizaData?.fechaDesde).add(1, 'M').format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='FIN DE PÓLIZA'
                            value={Moment(polizaData?.fechaDesde).add(6, 'M').format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='VIGENCIA'
                            value='SEMESTRAL'
                            disabled
                        />
                        <CustomTextInput
                            label='VALOR'
                            value={packageData?.price?.formattedValue}
                            disabled
                            underText={'PRÓX CUOTA ' + packageData?.price?.formattedValue}
                            underColor='var(--warningPrimary)'
                        />
                        <CustomTextInput
                            label='FORMA DE PAGO'
                            value={getFormaDePago(checkoutData?.paymentInfo?.paymentType)}
                            disabled
                        />
                    </div>
                </div>
            }
        </div>
    );

}

export default PolizasData;