import React, { useEffect, useState } from 'react'
import { 
    getPolizaData,
    getCheckoutData,
    getCodigoPostalList,
    getFinaerLists,
} from '../../../actions'
import './PolizasDueñoVivienda.css'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import CustomTextInput from '../../CustomTextInput/CustomTextInput';
import Moment from 'moment/moment'
import SelectorInput from '../../SelectorInput/SelectorInput';

const PolizasDueñoVivienda = (props: any) => {

    const [itemData, setItemData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [dueñoVivienda, setDueñoVivienda] = useState<any>(null)

    //LISTS
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);
    const [sexoList, setSexoList] = useState<any>(null);

    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionlidadList, setNacionalidadList] = useState<any>(null);
    const [profesionList, setProfesionList] = useState<any>(null);

    const [codigoPostalList, setCodigoPostalList] = useState<any>(null)

    useEffect(() => {
        getPolizaData(props.polizaId, setItemData, props.from, setLoading)
        getCodigoPostalList(setCodigoPostalList)
        getFinaerLists(setNacionalidadList, setProfesionList, 39)
        hardCodeLists()
    }, [])

    useEffect(() => {
        if(itemData) {
            getCheckoutData(itemData?.checkoutId, setCheckoutData, setLoading)
        }
    }, [itemData])

    useEffect(() => {
        var jsonString = null;
        if (checkoutData?.productInfo?.datosDueñoViviendaIncendio) {
            jsonString = checkoutData?.productInfo?.datosDueñoViviendaIncendio[0]
            jsonString = jsonString?.replace(/\\/g, '');
            const parsedData = JSON.parse(jsonString);
            setDueñoVivienda(parsedData)
        }
        else if (checkoutData?.productInfo?.datosDueñoViviendaMulticobertura) {
            jsonString = checkoutData?.productInfo?.datosDueñoViviendaMulticobertura[0]
            jsonString = jsonString?.replace(/\\/g, '');
            const parsedData = JSON.parse(jsonString);
            setDueñoVivienda(parsedData)
        }

    }, [checkoutData])

    const hardCodeLists = () => {
        const sexoListAux = [
            {
                descripcion: 'FEMENINO',
                codigo: 'F'
            },
            {
                descripcion: 'MASCULINO',
                codigo: 'M'
            },
            {
                descripcion: 'NO BINARIO',
                codigo: 'X'
            }
        ]
        setSexoList(sexoListAux);

        const estadoCivilListAux = [
            {
                descripcion: 'SOLTERO/A',
                codigo: 1
            },
            {
                descripcion: 'CASADO/A',
                codigo: 2
            },
            {
                descripcion: 'VIUDO/A',
                codigo: 3
            },
            {
                descripcion: 'DIVORCIADO/A',
                codigo: 4
            },
            {
                descripcion: 'SEPARADO/A',
                codigo: 5
            },
            {
                descripcion: 'CONVIVIENTE',
                codigo: 7
            }
        ]
        setEstadoCivilList(estadoCivilListAux);

        const tipoDocuListAux: any = [
            {
                descripcion: 'C.D.I.',
                codigo: 1
            },
            {
                descripcion: 'L.E.',
                codigo: 3
            },
            {
                descripcion: 'L.C.',
                codigo: 4
            },
            {
                descripcion: 'PASAPORTE',
                codigo: 5
            },
            {
                descripcion: 'D.N.I.',
                codigo: 6
            },
            {
                descripcion: 'C.U.I.T.',
                codigo: 8
            },
            {
                descripcion: 'C.U.I.L.',
                codigo: 9
            }
        ]
        setTipoDocuList(tipoDocuListAux);
    }

    return (
        <div className='polizasDueñoVivienda-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasDueñoVivienda-container-data'>
                    <h5 className='polizasDueñoVivienda-container-data-title bold'>Datos del titular del bien asegurado</h5>
                    <div className='polizasDueñoVivienda-container-data-section'>
                        <CustomTextInput
                            label='NOMBRE'
                            value={dueñoVivienda?.nombreDueño}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='APELLIDO'
                            value={dueñoVivienda?.apellidoDueño}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='FECHA DE NACIMIENTO'
                            value={Moment(dueñoVivienda?.fechaNacDueño).format('DD/MM/YYYY')}
                            disabled
                            required
                        />
                        <div className='polizasDueñoVivienda-container-data-section-selector'>
                            <div className='polizasDueñoVivienda-container-data-section-selector-label'>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-text bold medium'>TIPO DE DOCUMENTO</b>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <SelectorInput
                                disabled
                                id='TIPO DE DOCUMENTO'
                                placeholder='Seleccionar'
                                label='TIPO DE DOCUMENTO'
                                sortList={tipoDocuList}
                                value={tipoDocuList?.filter((a: any) => a.codigo == dueñoVivienda?.tipoDocuDueño)[0]?.descripcion} 
                            />
                        </div>
                        <CustomTextInput
                            label='NRO. DE DOCUMENTO'
                            value={dueñoVivienda?.numDocuDueño}
                            disabled
                            required
                        />
                        <div className='polizasDueñoVivienda-container-data-section-gender'>
                            <div className='polizasDueñoVivienda-container-data-section-gender-label'>
                                <b className='polizasDueñoVivienda-container-data-section-gender-label-text bold medium'>SEXO QUE FIGURA EN EL DNI</b>
                                <b className='polizasDueñoVivienda-container-data-section-gender-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <div className='polizasDueñoVivienda-container-data-section-gender-list'>
                                {sexoList?.map((a: any) => (
                                    <div className='polizasDueñoVivienda-container-data-section-gender-list-item'>
                                        <div className='polizasDueñoVivienda-container-data-section-gender-list-item-circle'>
                                            <div className='polizasDueñoVivienda-container-data-section-gender-list-item-circle-inner' style={{ backgroundColor: a.codigo === dueñoVivienda?.sexoDueño ? 'var(--gray300)' : 'transparent'}}/>
                                        </div>
                                        <p className='polizasDueñoVivienda-container-data-section-gender-list-item-text'>{a.codigo}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='polizasDueñoVivienda-container-data-section-selector'>
                            <div className='polizasDueñoVivienda-container-data-section-selector-label'>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-text bold medium'>ESTADO CIVIL</b>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <SelectorInput
                                disabled
                                id='ESTADO CIVIL'
                                placeholder='Seleccionar'
                                label='ESTADO CIVIL'
                                sortList={estadoCivilList}
                                value={estadoCivilList?.filter((a: any) => a.codigo == dueñoVivienda?.estadoCivilDueño)[0]?.descripcion} 
                            />
                        </div>
                        <div className='polizasDueñoVivienda-container-data-section-selector'>
                            <div className='polizasDueñoVivienda-container-data-section-selector-label'>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-text bold medium'>NACIONALIDAD</b>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <SelectorInput
                                disabled
                                id='NACIONALIDAD'
                                placeholder='Seleccionar'
                                label='NACIONALIDAD'
                                sortList={nacionlidadList}
                                value={nacionlidadList?.filter((a: any) => a.codigo == dueñoVivienda?.nacionalidadDueño)[0]?.descripcion} 
                            />
                        </div>
                        <div className='polizasDueñoVivienda-container-data-section-selector'>
                            <div className='polizasDueñoVivienda-container-data-section-selector-label'>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-text bold medium'>PROFESIÓN</b>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <SelectorInput
                                disabled
                                id='PROFESIÓN'
                                placeholder='Seleccionar'
                                label='PROFESIÓN'
                                sortList={profesionList}
                                value={profesionList?.filter((a: any) => a.codigo == dueñoVivienda?.profesionDueño)[0]?.descripcion} 
                            />
                        </div>
                        <div className='polizasDueñoVivienda-container-data-section-phoneInput'>
                            <div className='polizasDueñoVivienda-container-data-section-phoneInput-label'>
                                <b className='polizasDueñoVivienda-container-data-section-phoneInput-label-text bold medium'>TELÉFONO CELULAR</b>
                                <b className='polizasDueñoVivienda-container-data-section-phoneInput-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <div className='polizasDueñoVivienda-container-data-section-phoneInput-inputs'>
                                <div className='polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp' style={{ width: '30%' }} >
                                    <b className='polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp-text regular medium'>0</b>
                                    <input
                                        className='polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp-input'
                                        value={dueñoVivienda?.codigoAreaDueño}
                                        disabled
                                    />
                                </div>
                                <div className='polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp' style={{ width: '65%' }} >
                                    <b className='polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp-text regular medium'>15</b>
                                    <input
                                        className='polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp-input'
                                        value={dueñoVivienda?.celularDueño}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <CustomTextInput
                            label='EMAIL'
                            value={dueñoVivienda?.emailDueño}
                            disabled
                            required
                        />
                        <div className='polizasDueñoVivienda-container-data-section-invis'/>
                    </div>
                    <h5 className='polizasDueñoVivienda-container-data-title bold'>Datos del dueño de la vivienda</h5>
                    <div className='polizasDueñoVivienda-container-data-section'>
                        <div className='polizasDueñoVivienda-container-data-section-selector'>
                            <div className='polizasDueñoVivienda-container-data-section-selector-label'>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-text bold medium'>LOCALIDAD O CÓDIGO POSTAL</b>
                                <b className='polizasDueñoVivienda-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                            </div>
                            <SelectorInput
                                disabled
                                id='LOCALIDAD O CÓDIGO POSTAL'
                                placeholder='Seleccionar'
                                label='LOCALIDAD O CÓDIGO POSTAL'
                                sortList={codigoPostalList}
                                value={codigoPostalList ? codigoPostalList?.filter((a: any) => a.codigoPostal == dueñoVivienda?.codigoPostalDueño)[0]?.descripcion + ' (' + codigoPostalList?.filter((a: any) => a.codigoPostal == dueñoVivienda?.codigoPostalDueño)[0]?.codigoPostal + ')' : ''} 
                            />
                        </div>
                        <CustomTextInput
                            label='CALLE'
                            value={dueñoVivienda?.calleDueño}
                            disabled
                            required
                        />
                        <div className='polizasDueñoVivienda-container-data-section-threeList'>
                            <CustomTextInput
                                label='NRO.'
                                value={dueñoVivienda?.nroDueño}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='PISO'
                                value={dueñoVivienda?.pisoDueño}
                                disabled
                            />
                            <CustomTextInput
                                label='DPTO.'
                                value={dueñoVivienda?.dptoDueño}
                                disabled
                            />  
                        </div>
                    </div>
                </div>
            }
        </div>
    );

}

export default PolizasDueñoVivienda;