import React, { useEffect, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './UserDetails.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import Headers from '../../components/Headers/Headers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserData from '../../components/UserDatailsComponents/UserData/UserData';
import UserPolizas from '../../components/UserDatailsComponents/UserPolizas/UserPolizas';
import UserCotizaciones from '../../components/UserDatailsComponents/UserCotizaciones/UserCotizaciones';
import UserClaims from '../../components/UserDatailsComponents/UserClaims/UserClaims';
import DetailAccions from '../../components/DetailAccions/DetailAccions';
import Notification from '../../components/Notification/Notification';

const UserDetails = (props: any) => {

    const [headerListSelected, setHeaderSelectedList] = useState<string>('')
    const { hash }: any = useLocation();
    const { id }: any = useParams();
    const navigate = useNavigate();
    const [enableEdit, setEnabledEdit] = useState<boolean>(false);
    const [notification, setNotification] = useState({ success: false, text: '' });

    const accionList = [
        {
            name: 'Editar',
            onClick: () => setEnabledEdit(true),
            show: props.userData?.privileges?.filter(function(e: any) { return e === 'users:edit' }).length > 0
        }
    ]

    const headerList = [
        {
            name: 'Detalle de usuario',
            logicName: 'Detalle de usuario',
            click: () => {
                setHeaderSelectedList('Detalle de usuario')
                navigate('#Detalle de usuario')
            },
            component: <UserData userId={id} enableEdit={enableEdit} setEnabledEdit={setEnabledEdit} setNotification={setNotification}/>
        },
        {
            name: 'Coberturas',
            logicName: 'Coberturas',
            click: () => {
                setHeaderSelectedList('Coberturas')
                navigate('#Coberturas')
            },
            component: <UserPolizas userId={id}/>
        },
        {
            name: 'Cotizaciones',
            logicName: 'Cotizaciones',
            click: () => {
                setHeaderSelectedList('Cotizaciones')
                navigate('#Cotizaciones')
            },
            component: <UserCotizaciones userId={id}/>,
            disabled: true
        },
        {
            name: 'Reclamos',
            logicName: 'Reclamos',
            click: () => {
                setHeaderSelectedList('Reclamos')
                navigate('#Reclamos')
            },
            component: <UserClaims userId={id}/>,
            disabled: true
        },
    ]

    useEffect(() => {
        setHeaderSelectedList(hash.substring(1).replace(/%20/g, " "))
    }, [hash])

    return (
        <div className='userDetails-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='userDetails-container-main'>
                <div className='userDetails-container-main-hedear'>
                    <div className='userDetails-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <div className='userDetails-container-main-body'>
                    <div className='userDetails-container-main-body-top'>
                        <div className='userDetails-container-main-body-top-text'>
                            <h2 className='userDetails-container-main-body-top-text-title bold'>Ver Usuario</h2>
                            <div className='userDetails-container-main-body-top-text-volver' onClick={() => navigate('/users')}>
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.70711 0.292893C8.09763 0.683418 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976318 7.31658 -0.0976317 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976312 7.31658 -0.0976311 7.70711 0.292893Z" fill="var(--primary500)"/>
                                </svg>
                                <p className='userDetails-container-main-body-top-text-volver-text bold small'>&nbsp;&nbsp;Volver a Usuarios</p>
                            </div>
                        </div>
                        <DetailAccions accionList={accionList}/>
                        <Notification
                            notification={notification}
                            setNotification={setNotification}
                        />
                    </div>
                    <div className='userDetails-container-main-body-content'>
                        <Headers 
                            list={headerList}
                            selected={headerListSelected}
                        />
                        {headerList?.filter((a: any) => a.name === headerListSelected)[0]?.component}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;