const hexToRgba = (hex: any) => {
    const bigint = parseInt(hex?.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const rgb = `${r}, ${g}, ${b}`;
    return `rgba(${rgb}, ${0.10})`;
};

export const estadoCoberturaList = [
    {
        title: 'Vigente',
        text: 'Una cobertura activa durante un período de tiempo. Implica que el asegurado está protegido contra los riesgos y eventos especificados.',
        color: '#008744',
        colorOpacity: hexToRgba('#008744'),
        status: 'FINISHED'
    },
    {
        title: 'No vigente',
        text: 'Una cobertura inactiva. En otras palabras, la cobertura no está en vigor ni proporciona protección ni beneficios en un momento determinado.',
        color: '#A705A7',
        colorOpacity: hexToRgba('#A705A7'),
        status: 'NOT_CURRENT'
    },
    {
        title: 'Pago rechazado',
        text: 'La transacción para pagar la prima de la cobertura no se completó con éxito debido a problemas con la tarjeta de crédito, falta de fondos u otras razones relacionadas con el proceso de pago.',
        color: '#D12440',
        colorOpacity: hexToRgba('#D12440'),
        status: 'REJECTED_PAYMENT'
    },
    {
        title: 'Emisión fallida',
        text: 'Una propuesta que ha sido anulada o interrumpida por la cía aseguradora antes de emitirse.',
        color: '#858585',
        colorOpacity: hexToRgba('#858585'),
        status: 'FAILED_EMISSION'
    }
]