import React, { useEffect, useState } from 'react'
import { 
    getUserClientDataPardo,
    getFinaerLists,
    editUserProfile
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './UserData.css'
import Moment from 'moment/moment'
import Lottie from 'react-lottie'
import loadingLottie from '../../../assets/lotties/loading.json'
import MainButton from '../../MainButton/MainButton'
import SelectorInput from '../../SelectorInput/SelectorInput'
import Modal from 'react-modal';
import CancelModal from '../../Modals/CancelModal/CancelModal'

const UserData = (props: any) => {

    //ORIGINAL DATA
    const [userClientData, setUserClientData] = useState<any>(null);

    //EDITABLE INPUTS
    const [tipoDeDocumento, setTipoDeDocumento] = useState<any>(null);
    const [nroDeDocumento, setNroDeDocumento] = useState<string>('');
    const [sexo, setSexo] = useState<any>(null);

    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    const [nacionalidad, setNacionlidad] = useState<any>(null);
    const [profesion, setProfesion] = useState<any>(null);

    const [telefono, setTelefono] = useState<string>('');

    //LISTS
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);
    const [sexoList, setSexoList] = useState<any>(null);

    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionlidadList, setNacionalidadList] = useState<any>(null);
    const [profesionList, setProfesionList] = useState<any>(null);

    //OTROS
    const [loading, setLoading] = useState<boolean>(false);
    const [cancelAlert, setCancelAlert] = useState<boolean>(false);

    //USE EFFECTS
    useEffect(() => {
        getUserClientDataPardo(props.userId, setUserClientData, props.from, setLoading)
        getFinaerLists(setNacionalidadList, setProfesionList, 39)
        hardCodeLists()
    }, [])

    const hardCodeLists = () => {
        const sexoListAux = [
            {
                descripcion: 'FEMENINO',
                codigo: 'F'
            },
            {
                descripcion: 'MASCULINO',
                codigo: 'M'
            },
            {
                descripcion: 'NO BINARIO',
                codigo: 'X'
            }
        ]
        setSexoList(sexoListAux);

        const estadoCivilListAux = [
            {
                descripcion: 'SOLTERO/A',
                codigo: 1
            },
            {
                descripcion: 'CASADO/A',
                codigo: 2
            },
            {
                descripcion: 'VIUDO/A',
                codigo: 3
            },
            {
                descripcion: 'DIVORCIADO/A',
                codigo: 4
            },
            {
                descripcion: 'SEPARADO/A',
                codigo: 5
            },
            {
                descripcion: 'CONVIVIENTE',
                codigo: 7
            }
        ]
        setEstadoCivilList(estadoCivilListAux);

        const tipoDocuListAux: any = [
            {
                descripcion: 'C.D.I.',
                codigo: 1
            },
            {
                descripcion: 'L.E.',
                codigo: 3
            },
            {
                descripcion: 'L.C.',
                codigo: 4
            },
            {
                descripcion: 'PASAPORTE',
                codigo: 5
            },
            {
                descripcion: 'D.N.I.',
                codigo: 6
            },
            {
                descripcion: 'C.U.I.T.',
                codigo: 8
            },
            {
                descripcion: 'C.U.I.L.',
                codigo: 9
            }
        ]
        setTipoDocuList(tipoDocuListAux);
    }

    useEffect(() => {
        if(props.enableEdit) loadUserDataForEdit()
    }, [props.enableEdit])

    //FUNCTIONS
    const loadUserDataForEdit = () => {
        if(userClientData?.documentId) setTipoDeDocumento(parseInt(userClientData?.documentId))
        if(userClientData?.documentNumber) setNroDeDocumento(userClientData?.documentNumber)
        if(userClientData?.sexId) setSexo(userClientData?.sexId)

        if(userClientData?.civilStatusId) setEstadoCivil(userClientData?.civilStatusId)
        if(userClientData?.nacionalityId) setNacionlidad(userClientData?.nacionalityId)
        if(userClientData?.profesion) setProfesion(userClientData?.profesion)

        if(userClientData?.phone) setTelefono(userClientData?.phone)
    }

    const isDisabled = () => {
        return (
            !tipoDeDocumento || nroDeDocumento?.length !== 8 ||
            !sexo || !estadoCivil || !nacionalidad ||
            telefono === ''
        )
    }

    //MODALS
    const CancelAlert = () => {
        return (
            <Modal
                isOpen={cancelAlert}
                onRequestClose={() => setCancelAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <CancelModal
                    cancel={() => setCancelAlert(false)}
                    aceptar={() => {
                        setCancelAlert(false)
                        props.setEnabledEdit(false)
                    }}
                />
            </Modal>
        )
    }

    //MAIN RENDER
    return (
        <div className='userData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <>
                    <div className='userData-container-data'>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='FECHA DE CREACIÓN'
                                value={Moment(userClientData?.creationDate).format('DD/MM/YYYY')}
                                disabled
                            />
                            <CustomTextInput
                                label='ID'
                                value={userClientData?.id}
                                disabled
                            />
                            <div className='userData-container-data-section-status'>
                                <p className='userData-container-data-section-status-label bold medium'>PERFIL</p>
                                <p 
                                    className='userData-container-data-section-status-data regular small'
                                    style={{
                                        backgroundColor: userClientData?.profileCompleted ? 'var(--accent300)' : 'var(--accent500)'
                                    }}
                                >
                                    {userClientData?.profileCompleted ? 'COMPLETO' : 'INCOMPLETO'}
                                </p>
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos personales</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='NOMBRE'
                                value={userClientData?.name}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='APELLIDO'
                                value={userClientData?.lastName}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='FECHA DE NACIMIENTO'
                                value={Moment(userClientData?.birthDate).format('DD/MM/YYYY')}
                                disabled
                                required
                            />
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>TIPO DE DOCUMENTO</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !tipoDocuList}
                                    id='TIPO DE DOCUMENTO'
                                    placeholder='Seleccioná el tipo de documento'
                                    label='TIPO DE DOCUMENTO'
                                    sortList={tipoDocuList}
                                    itemSelected={(a: any) => setTipoDeDocumento(a?.codigo)}
                                    value={props.enableEdit ? tipoDocuList?.filter((a: any) => a.codigo === tipoDeDocumento)[0]?.descripcion : tipoDocuList?.filter((a: any) => a.codigo === parseInt(userClientData?.documentId))[0]?.descripcion}
                                />
                            </div>
                            <CustomTextInput
                                label='NRO DE DOCUMENTO'
                                value={props.enableEdit ? nroDeDocumento : userClientData?.documentNumber}
                                disabled={!props.enableEdit}
                                required
                                onChange={(e: any) => setNroDeDocumento(e.target.value.replace(/[^0-9]+/g, ''))}
                                maxLength={8}
                                error={props.enableEdit ? nroDeDocumento.length !== 8 : userClientData?.documentNumber?.length !== 8}
                                success={props.enableEdit ? nroDeDocumento.length === 8 : false}
                            />
                            <div className='userData-container-data-section-gender'>
                                <div className='userData-container-data-section-gender-label'>
                                    <b className='userData-container-data-section-gender-label-text bold medium'>SEXO QUE FIGURA EN EL DNI</b>
                                    <b className='userData-container-data-section-gender-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <div className='userData-container-data-section-gender-list'>
                                    {sexoList?.map((a: any) => (
                                        <div className='userData-container-data-section-gender-list-item'>
                                            <div className='userData-container-data-section-gender-list-item-circle'>
                                                <div className='userData-container-data-section-gender-list-item-circle-inner' style={{ backgroundColor: a.codigo === userClientData?.sexId ? 'var(--gray300)' : 'transparent'}}/>
                                            </div>
                                            <p className='userData-container-data-section-gender-list-item-text'>{a.codigo}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>ESTADO CIVIL</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !estadoCivilList}
                                    id='ESTADO CIVIL'
                                    placeholder='Seleccioná el estado civil'
                                    label='ESTADO CIVIL'
                                    sortList={estadoCivilList}
                                    itemSelected={(a: any) => setEstadoCivil(a?.codigo)}
                                    value={props.enableEdit ? estadoCivilList?.filter((a: any) => a.codigo === estadoCivil)[0]?.descripcion : estadoCivilList?.filter((a: any) => a.codigo === userClientData?.civilStatusId)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>NACIONALIDAD</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !nacionlidadList}
                                    id='NACIONALIDAD'
                                    placeholder='Seleccioná la nacionalidad'
                                    label='NACIONALIDAD'
                                    sortList={nacionlidadList}
                                    itemSelected={(a: any) => setNacionlidad(a?.codigo)}
                                    value={props.enableEdit ? nacionlidadList?.filter((a: any) => a.codigo === nacionalidad)[0]?.descripcion : nacionlidadList?.filter((a: any) => a.codigo === userClientData?.nacionalityId)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>PROFESIÓN</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !nacionlidadList}
                                    id='PROFESIÓN'
                                    placeholder='Seleccioná la profesión'
                                    label='PROFESIÓN'
                                    sortList={profesionList}
                                    itemSelected={(a: any) => setProfesion(a?.codigo)}
                                    value={props.enableEdit ? profesionList?.filter((a: any) => a.codigo === profesion)[0]?.descripcion : profesionList?.filter((a: any) => a.codigo === userClientData?.profesion)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-phoneInput'>
                                <div className='userData-container-data-section-phoneInput-label'>
                                    <b className='userData-container-data-section-phoneInput-label-text bold medium'>TELÉFONO CELULAR</b>
                                    <b className='userData-container-data-section-phoneInput-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <div className='userData-container-data-section-phoneInput-inputs'>
                                    <div className='userData-container-data-section-phoneInput-inputs-inputWrapp' style={{ width: '30%' }} >
                                        <b className='userData-container-data-section-phoneInput-inputs-inputWrapp-text regular medium'>0</b>
                                        <input
                                            className='userData-container-data-section-phoneInput-inputs-inputWrapp-input'
                                            value={props.enableEdit ? telefono : userClientData?.phone?.substring(0, 2)}
                                            disabled
                                        />
                                    </div>
                                    <div className='userData-container-data-section-phoneInput-inputs-inputWrapp' style={{ width: '65%' }} >
                                        <b className='userData-container-data-section-phoneInput-inputs-inputWrapp-text regular medium'>15</b>
                                        <input
                                            className='userData-container-data-section-phoneInput-inputs-inputWrapp-input'
                                            value={props.enableEdit ? telefono : userClientData?.phone?.substring(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos de cuenta</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='EMAIL'
                                value={userClientData?.username}
                                disabled
                                required
                            />
                        </div>
                    </div>
                    {props.enableEdit &&
                        <div className='userData-container-editable'>
                            <div className='userData-container-editable-editing'>
                                <MainButton
                                    text='CANCELAR'
                                    inverted
                                    onPress={() => setCancelAlert(true)}
                                />
                                <MainButton
                                    text='GUARDAR'
                                    disabled={isDisabled()}
                                    onPress={() => {
                                        props.setEnabledEdit(false)
                                        const data = {
                                            'name': userClientData?.name,
                                            'lastName': userClientData?.lastName,
                                            'birthDate': Moment(userClientData?.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),

                                            'documentId': tipoDeDocumento,
                                            'documentNumber': nroDeDocumento,
                                            'sexId': sexo,

                                            'civilStatus': estadoCivil,
                                            'nacionalityId': nacionalidad,
                                            'profesion': profesion,
                                            
                                            'phone': telefono,
                                            'userName': userClientData?.username,

                                        }
                                        editUserProfile(data, userClientData?.id, setLoading, setUserClientData, props.setNotification)
                                    }}
                                />
                            </div>
                        </div>
                    }
                </>
            }
            {CancelAlert()}
        </div>
    );

}

export default UserData;