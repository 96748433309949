import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import OrdenarPor from "../OrdenarPor/OrdenarPor";
import "./TableFilters.css";
import DownloadIcon from "../../assets/images/svgs/downloadIcon.svg";
import Moment from "moment/moment";
import OpcionComponent from "./OpcionComponent/OpcionComponent";
import MainButton from "../MainButton/MainButton";
import ChipComponent from "./ChipComponent";
import txtImage from "../../assets/images/svgs/txt.svg";
import Modal from 'react-modal';
import CSVUploader from "../CSVUploader/CSVUploader";

const TableFilters = (props: any) => {
  const [textFilter, setTextFilter] = useState<any>(null);
  const [finalTextFilter, setFinalTextFilter] = useState<any>(null);
  const [localTextLoader, setLocalTextLoader] = useState<boolean>(false);
  const [timeout, timeoutChange] = useState<any>(0);
  const [sortTable, setSortTable] = useState<any>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [includedMenu, setIncludedMenu] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>([]);
  const [finalFilter, setFinalFilter] = useState<any>([]);
  const [activeFilterNames, setActiveFilterNames] = useState<any>([]);
  const [activeObjects, setActiveObjects] = useState<any>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [uploadModal, setUploadModal] = useState<boolean>(false);

  const UploadModal = () => {
    return (
      <Modal
        isOpen={uploadModal}
        onRequestClose={() => setUploadModal(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: '30%',
            padding: '20px'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10
          }
        }}
      >
        <CSVUploader
          onDismiss={() => setUploadModal(false)}
          type={props.type}
        />
      </Modal>
    )
  }

  useEffect(() => {
    //if coberturas activas button was clicked in the dashboard
    if (sessionStorage.getItem("Vigente")) {
      const timer = setTimeout(() => {
        setActiveFilterNames(["Vigente"]);
        setActiveObjects(() => {
          return [
            {
              "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Vigente'" }
            },
          ];
        });
        setFilter(() => {
          return [
            {
              $or: [
                {
                  "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Vigente'" }
                },
              ],
              title: "ESTADO",
            },
          ];
        });
        setFilterApplied(true);
      }, 1000);
      //limpieza del useEffect
      return () => clearTimeout(timer);
    }
    if (sessionStorage.getItem("Emisión fallida")) {
      const timer = setTimeout(() => {
        setActiveFilterNames(["Emisión fallida"]);
        setActiveObjects(() => {
          return [
            {
              "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Emisión fallida'" }
            },
          ];
        });
        setFilter(() => {
          return [
            {
              $or: [
                {
                  "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Emisión fallida'" }
                },
              ],
              title: "ESTADO",
            },
          ];
        });
        setFilterApplied(true);
      }, 1000);
      //limpieza del useEffect
      return () => clearTimeout(timer);
    }
    if (sessionStorage.getItem("Solicitud pendiente")) {
      const timer = setTimeout(() => {
        setActiveFilterNames(["Solicitud pendiente"]);
        setActiveObjects(() => {
          return [
            {
              "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Solicitud pendiente'" }
            },
          ];
        });
        setFilter(() => {
          return [
            {
              $or: [
                {
                  "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Solicitud pendiente'" }
                },
              ],
              title: "ESTADO",
            },
          ];
        });
        setFilterApplied(true);
      }, 1000);
      //limpieza del useEffect
      return () => clearTimeout(timer);
    }
    if (sessionStorage.getItem("Solicitud de baja")) {
      const timer = setTimeout(() => {
        setActiveFilterNames(["Solicitud de baja"]);
        setActiveObjects(() => {
          return [
            {
              "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Solicitud de baja'" }
            },
          ];
        });
        setFilter(() => {
          return [
            {
              $or: [
                {
                  "checkout.status": { $rawQuery: "JSON_EXTRACT(estado, '$.h1') = 'Solicitud de baja'" }
                },
              ],
              title: "ESTADO",
            },
          ];
        });
        setFilterApplied(true);
      }, 1000);
      //limpieza del useEffect
      return () => clearTimeout(timer);
    }
    //vendedores activos
    if (sessionStorage.getItem("Activo")) {
      const timer = setTimeout(() => {
        setActiveFilterNames(["Activo"]);
        setActiveObjects(() => {
          return [
            {
              "JSON_EXTRACT(estado, '$.h1')": { $eq: "Activo" },
            },
          ];
        });
        setFilter(() => {
          return [
            {
              $or: [
                {
                  "JSON_EXTRACT(estado, '$.h1')": { $eq: "Activo" },
                },
              ],
              title: "ESTADO",
            },
          ];
        });
        setFilterApplied(true);
      }, 1000);
      //limpieza del useEffect
      return () => clearTimeout(timer);
    }
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    //update the disabled state of APLICAR
    if (activeFilterNames.length !== 0) {
      setIsChanged(true);
    }
  }, [activeFilterNames]);

  useEffect(() => {
  }, [activeObjects]);

  useEffect(() => {
    if (includedMenu === false) {
      setFinalFilter(reformFilterArray());
    }
  }, [filter]);

  useEffect(() => {
    //i call server only if it is not the first render
    /* if (!isFirstRender) { */
    const data = getDataForPage(1, null, textFilter, finalFilter);
    props.sortChange(data);
    setIsChanged(false);
  }, [finalFilter]);

  //to check if two objects are equal
  function areObjectsEqual(obj1: any, obj2: any) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (typeof val1 === "object" && typeof val2 === "object") {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }
    return true;
  }

  function replaceValue(obj: any, keyToReplace: any, replacementText: any) {
    if (obj instanceof Object) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "string") {
            obj[key] = obj[key].replace(
              new RegExp(keyToReplace, "g"),
              replacementText
            );
          } else if (obj[key] instanceof Object) {
            replaceValue(obj[key], keyToReplace, replacementText);
          }
        }
      }
    }
    return obj;
  }

  const getDataForPage = (
    pageNumber: number,
    sortForm: any,
    textForm: any,
    filterForm: any
  ) => {
    let sort: any = null;
    let page: any = null;
    let filter: any = [];
    let data: any = {};
    if (sortForm) {
      sort = sortForm;
      data["sort"] = sort;
    }
    if (filterForm) {
      filter = filterForm;
      data["filter"] = filter;
    }
    if (pageNumber) {
      page = {
        number: pageNumber,
        size: 40,
      };
      data["page"] = page;
    }
    if (textForm) {
      const newFilter = replaceValue(
        props.formData?.excluded?.filters[0]?.elements[0]?.filter,
        finalTextFilter ? finalTextFilter : "\\$VALOR",
        textForm
      );
      setFinalTextFilter(textForm);
      filter.push(newFilter);
      data["filter"] = filter;
    }
    props.setDataConfig(data);
    return data;
  };

  const handleSearchTextChange = (text: string) => {
    setTextFilter(text);
    if (text === "") {
      applyFilter();
    } else {
      setLocalTextLoader(true);
      if (timeout) timeoutChange(clearTimeout(timeout));
      timeoutChange(
        setTimeout(() => {
          applyFilter();
          setLocalTextLoader(false);
        }, 2000)
      );
    }
  };

  const handleSorting = (sort: any) => {
    setSortTable(sort?.config);
    const data = getDataForPage(1, sort?.config, textFilter, finalFilter);
    props.sortChange(data);
  };

  const handleClose = () => {
    setIncludedMenu(false);
    //if there is a close click
    //check the concistency of ActiveFilterNames array, it must have the same keys as sessionStorage
    setActiveFilterNames(() => {
      const updatedNames = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const keyName = sessionStorage.key(i);
        updatedNames.push(keyName);
      }
      return updatedNames;
    });
  };

  const handleChipClose = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: any
  ) => {
    const chipElement = event.currentTarget;
    chipElement.remove();
    const toDelete = activeObjects[index];

    setActiveFilterNames(
      activeFilterNames.filter((filterName: any, pos: any) => pos !== index)
    );

    setActiveObjects(
      activeObjects.filter((filterObject: any, pos: number) => pos !== index)
    );

    setFilter((prevFilter: any) => {
      let updatedFilter = prevFilter.map((eachFilter: any) => {
        if (eachFilter.hasOwnProperty("$or")) {
          const objectIndex = eachFilter?.$or?.findIndex((obj: any) =>
            areObjectsEqual(obj, toDelete)
          );
          if (objectIndex === -1) {
            return eachFilter;
          } else {
            const orUpdated = eachFilter.$or.filter(
              (val: any, index: number) => index !== objectIndex
            );
            const filterUpdated = {
              ...eachFilter,
              $or: orUpdated,
            };
            return filterUpdated;
          }
        } else {
          const dates = eachFilter["product_insured_user.date_insured"];
          if (dates !== toDelete["product_insured_user.date_insured"]) {
            return eachFilter;
          } else {
            return null;
          }
        }
      });
      updatedFilter = updatedFilter.filter((fil: any) => fil !== null);
      return updatedFilter;
    }); //fin setFilter
  }; //fin chipCLose

  const addToFilter = (filterTitle: any, value: any, filterOption: string) => {
    //filter name array (only strings with names)
    setActiveFilterNames((prevNames: any) => {
      const updatedNames = [...prevNames];
      const existingNameIndex = updatedNames.findIndex(
        (uniqueName) => uniqueName === filterOption
      );
      //if doesn't exist add it, else splice it
      existingNameIndex === -1 ? updatedNames.push(filterOption) : evaluate();

      function evaluate() {
        if (filterOption !== "Desde" && filterOption !== "Hasta") {
          updatedNames.splice(existingNameIndex, 1);
        }
      }
      return updatedNames;
    });
    //filter objects array to find what to delete when a chip is closed
    setActiveObjects((prevObjects: any) => {
      const updatedObjects = [...prevObjects];
      //check if exists
      const existingObjectIndex = updatedObjects.findIndex((obj) =>
        areObjectsEqual(obj, value)
      );
      //if doesn't exist add it, else splice it
      existingObjectIndex === -1 ? updatedObjects.push(value) : evaluate();

      function evaluate() {
        if (filterOption !== "Desde" && filterOption !== "Hasta") {
          updatedObjects.splice(existingObjectIndex, 1);
        }
      }
      return updatedObjects;
    });

    setFilter((prevFilter: any) => {
      // Check if the 'filterTitle' exists in the 'filter' array
      //check if FECHA
      if (filterTitle === "FECHA") {
        const existingFilterIndex = prevFilter.findIndex(
          (filter: any) => filter.title === filterOption
        );
        const updatedObject = value;
        if (existingFilterIndex === -1) {
          const updatedFilter = [
            ...prevFilter,
            { title: filterOption, ...updatedObject },
          ];
          return updatedFilter;
        } else {
          const updatedFilter = prevFilter
            .map((filter: any, index: number) => {
              if (index === existingFilterIndex) {
                return { title: filterOption, ...updatedObject };
              }
              return filter;
            })
            .filter((fil: any) => fil !== null);
          return updatedFilter;
        }
      }

      const existingFilterIndex = prevFilter.findIndex(
        (filter: any) => filter.$or && filter.title === filterTitle
      );
      if (existingFilterIndex === -1) {
        // If the 'filterTitle' doesn't exist, create a new filter object with an '$or' array
        const updatedFilter = [
          ...prevFilter,
          { title: filterTitle, $or: [value] },
        ];
        return updatedFilter;
      }

      //if the filter title already exists
      const existingFilter = prevFilter[existingFilterIndex];
      //does the value already exists ?  yes:i took it out, no:i add it to the array
      const updatedOrArray = existingFilter.$or.some(
        (val: any) => areObjectsEqual(val,value)
      )
        ? existingFilter.$or.filter((val: any) => !areObjectsEqual(val,value))
        : [...existingFilter.$or, value];

      const updatedFilter = [...prevFilter];
      updatedFilter[existingFilterIndex] = {
        ...existingFilter,
        $or: updatedOrArray,
      };

      return updatedFilter;
    });
  };

  const reformFilterArray = () => {
    sessionStorage.clear();
    const names = [...activeFilterNames];
    names.forEach((activeFilter: any) => {
      if (activeFilter === "Desde") {
        const desde = filter.find((fil: any) => fil.title === "Desde");
        //busco la propiedad que no sea title
        for (const prop in desde) {
          prop !== "title" &&
            sessionStorage.setItem(
              activeFilter,
              JSON.stringify(
                Moment(desde[prop]["$gte"]).add(1, "day").format("DD/MM/YYYY")
              )
            );
        }
        //"product_insured_user.date_insured" en coberturas
      } else if (activeFilter === "Hasta") {
        const hasta = filter.find((fil: any) => fil.title === "Hasta");
        //busco la propiedad que no sea title
        for (const prop in hasta) {
          prop !== "title" &&
            sessionStorage.setItem(
              activeFilter,
              JSON.stringify(
                Moment(hasta[prop]["$lte"]).add(1, "day").format("DD/MM/YYYY")
              )
            );
        }
        //"product_insured_user.date_insured" en coberturas
      } else {
        sessionStorage.setItem(activeFilter, JSON.stringify(true));
      }
    });

    const updatedFilter = filter
      .map((filtro: any) => {
        let { title, ...rest } = filtro;
        rest["$or"]?.length === 1 && (rest = rest["$or"][0]);
        rest["$or"]?.length === 0 && (rest = null); // Set rest to null if $or length is 0
        return rest;
      })
      .filter((rest: any) => rest !== null); // Filter out null values from the array
    return updatedFilter;
  };

  function applyFilter() {
    setFilterApplied(true);
    setIncludedMenu(false);
    setFinalFilter(reformFilterArray());
    setIsChanged(false);
  }

  return (
    <>
      <div className="tableFilters-filters">
        <div className="tableFilters-filters-excluded">
          {props.formData?.excluded &&
            props.formData?.excluded?.filters?.map((a: any) => (
              <div
                className="tableFilters-filters-excluded-textInputWrapper"
                key={a.title}
              >
                <CustomTextInput
                  id="text filter input"
                  placeholder={a.title}
                  onChange={(e: any) => handleSearchTextChange(e.target.value)}
                  value={textFilter ? textFilter : ""}
                  lupa={() => handleSearchTextChange("")}
                  loading={localTextLoader ? "true" : null}
                />
              </div>
            ))}
        </div>
        {props.formData?.included && (
          <div className="tableFilters-filters-included">
            <div
              className="tableFilters-filters-included-main"
              onClick={() => setIncludedMenu(!includedMenu)}
            >
              <span
                className="material-symbols-rounded bold"
                style={{ fontSize: 18, color: 'var(--primary500)' }}
              >
                {props.formData?.included?.icon}
              </span>
              <b className="tableFilters-filters-included-main-title bold medium">
                {props.formData?.included?.text?.toUpperCase()}
              </b>
            </div>
            {includedMenu && (
              <div className="tableFilters-filters-included-menu">
                <div className="tableFilters-filters-included-menu-title">
                  <b className="tableFilters-filters-included-menu-title-text bold medium">
                    Filtros
                  </b>
                  <span
                    className="material-symbols-rounded bold medium"
                    onClick={() => handleClose()}
                    style={{ cursor: "pointer", fontSize: 20 }}
                  >
                    close
                  </span>
                </div>
                {props.formData?.included?.scrollableFilters && <div className="tableFilters-filters-included-menu-list-item-line"></div>}
                {props.formData?.included?.scrollableFilters && 
                  <div className="tableFilters-filters-included-menu-listScroll">
                    {props.formData?.included?.scrollableFilters?.map((a: any, index: any) => (
                      <div
                        className="tableFilters-filters-included-menu-list-item"
                        key={a.title}
                      >
                        {index !== 0 && <div className="tableFilters-filters-included-menu-list-item-line"></div>}
                        <p className="tableFilters-filters-included-menu-list-item-title regular xSmall">
                          {a.title}
                        </p>
                        <div
                          className="tableFilters-filters-included-menu-list-item-opcionList"
                          style={{
                            flexDirection:
                              a.contatenation === "$or" ? "column" : "row",
                          }}
                        >
                          {a.elements?.map((b: any) => (
                            <OpcionComponent
                              key={b.text}
                              data={b}
                              type={a.type}
                              opcionUpdated={(value: any, opcionText: any) => {
                                if(opcionText) addToFilter(a.title, value, opcionText)
                                else addToFilter(a.title, value, b.text)
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                }
                {props.formData?.included?.fixedFilters && 
                  <div className="tableFilters-filters-included-menu-list">
                    {props.formData?.included?.fixedFilters?.map((a: any) => (
                      <div
                        className="tableFilters-filters-included-menu-list-item"
                        key={a.title}
                      >
                        <div className="tableFilters-filters-included-menu-list-item-line"></div>
                        <p className="tableFilters-filters-included-menu-list-item-title regular xSmall">
                          {a.title}
                        </p>
                        <div
                          className="tableFilters-filters-included-menu-list-item-opcionList"
                          style={{
                            flexDirection:
                              a.contatenation === "$or" ? "column" : "row",
                          }}
                        >
                          {a.elements?.map((b: any) => (
                            <OpcionComponent
                              key={b.text}
                              data={b}
                              type={a.type}
                              opcionUpdated={(value: any, opcionText: any) => {
                                if(opcionText) addToFilter(a.title, value, opcionText)
                                else addToFilter(a.title, value, b.text)
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                }
                <div className="tableFilters-filters-included-menu-aplicar">
                  <MainButton
                    id="APLICAR"
                    text="APLICAR"
                    sinFondo
                    disabled={!isChanged}
                    onPress={() => applyFilter()}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {props.canUpload && 
          <div className='tableFilters-filters-subirButon'>
            <MainButton 
              text='SUBIR ARCHIVO'
              onPress={() => setUploadModal(true)}
            />
          </div>
        }
        <OrdenarPor
          itemSelected={(a: any) => handleSorting(a)}
          sortList={props.chartData?.visualization?.sorts}
          default={
            props.chartData?.visualization?.sorts?.filter(
              (a: any) => a.default
            )[0]?.name
          }
        />
        {props.canDownload && (
          <div
            className="tableFilters-filters-download"
            onClick={() => props.download(setDownloading)}
          >
            {downloading ? (
              <span className="tableFilters-filters-loader"></span>
            ) : (
              <ReactSVG src={DownloadIcon} />
            )}
            <p className="tableFilters-filters-download-text">
              &nbsp;{downloading ? "DESCARGANDO" : "DESCARGAR"}
            </p>
            <span className="tableFilters-filters-download-tooltiptext">
              Vas a descargar un archivo .csv con las búsquedas/filtros que
              hayas aplicado.
            </span>
          </div>
        )}
        {props.canDownloadTxt && (
          <div className="tableFilters-filters-downloadTxt" onClick={() => {}}>
            <img
              className="tableFilters-filters-downloadTxt-text"
              src={txtImage}
            />
            <span className="tableFilters-filters-downloadTxt-tooltiptext">
              Descargar como .txt
            </span>
          </div>
        )}
      </div>
      <div className="tableFilters-chips">
        {filterApplied &&
          activeFilterNames.map((filterName: string, index: any) => (
            <ChipComponent
              key={index + filterName}
              text={filterName}
              onClose={(event) => handleChipClose(event, index)}
            />
          ))}
        {filterApplied && activeFilterNames.length !== 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: "2px",
            }}
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
            >
            <p className="borrar-filtros-text">Borrar filtros</p>
          </div>
        )}
      </div>
      {UploadModal()}
    </>
  );
};

export default TableFilters;
