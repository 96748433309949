import React, { useEffect, useState } from 'react'
import { 
    getPolizaData,
    getCheckoutData,
    getCodigoPostalList,
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasItem.css'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import GoogleMapReact from 'google-map-react';
import SelectorInput from '../../SelectorInput/SelectorInput'

const PolizasItem = (props) => {

    const [itemData, setItemData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [checkoutData, setCheckoutData] = useState(null)
    const [packageData, setPackageData] = useState(null)
    const [datosVivienda, setDatosVivienda] = useState(null)

    const [codigoPostalList, setCodigoPostalList] = useState(null)

    const googleApiKey = "AIzaSyDtIR6V9MxunQYEurGdpeqOLtlr_GkHZ9Q";

    useEffect(() => {
        getPolizaData(props.polizaId, setItemData, props.from, setLoading)
        getCodigoPostalList(setCodigoPostalList)
    }, [])

    useEffect(() => {
        if(itemData) {
            getCheckoutData(itemData?.checkoutId, setCheckoutData, setLoading)
        }
    }, [itemData])

    useEffect(() => {
        if(checkoutData) {
            setPackageData(checkoutData?.offer?.packages?.filter((a) => a?.id === checkoutData?.offer?.currentPackage)[0])
        }
    }, [checkoutData])

    useEffect(() => {
        var jsonString = null;
        if (checkoutData?.productInfo?.datosViviendaIncendio) {
            jsonString = checkoutData?.productInfo?.datosViviendaIncendio[0]
            jsonString = jsonString?.replace(/\\/g, '');
            const parsedData = JSON.parse(jsonString);
            setDatosVivienda(parsedData)
        }
        else if (checkoutData?.productInfo?.datosViviendaMulticobertura) {
            jsonString = checkoutData?.productInfo?.datosViviendaMulticobertura[0]
            jsonString = jsonString?.replace(/\\/g, '');
            const parsedData = JSON.parse(jsonString);
            setDatosVivienda(parsedData)
        }

    }, [checkoutData])

    return (
        <div className='polizasItem-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasItem-container-data'>
                    <div className='polizasItem-container-data-section'>
                        <CustomTextInput
                            label='CATEGORÍA'
                            value={itemData?.productPolicyDTO?.categoryName}
                            disabled
                        />
                        <CustomTextInput
                            label='PLAN'
                            value={packageData?.description}
                            disabled
                        />
                    </div>
                    {checkoutData?.productInfo && <div className='polizasItem-container-data-item'>
                        <h5 className='polizasItem-container-data-item-title bold'>Datos de la vivienda</h5>
                        <div className='polizasItem-container-data-item-data'>
                            <div className='polizasItem-container-data-item-data-selector'>
                                <div className='polizasItem-container-data-item-data-selector-label'>
                                    <b className='polizasItem-container-data-item-data-selector-label-text bold medium'>LOCALIDAD O CÓDIGO POSTAL</b>
                                    <b className='polizasItem-container-data-item-data-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='LOCALIDAD O CÓDIGO POSTAL'
                                    placeholder='Seleccionar'
                                    label='LOCALIDAD O CÓDIGO POSTAL'
                                    sortList={codigoPostalList}
                                    value={codigoPostalList ? codigoPostalList?.filter((a) => a.codigoPostal == datosVivienda?.codigoPostalVivienda)[0]?.descripcion + ' (' + codigoPostalList?.filter((a) => a.codigoPostal == datosVivienda?.codigoPostalVivienda)[0]?.codigoPostal + ')' : ''} 
                                />
                            </div>
                            <CustomTextInput
                                label='CALLE'
                                value={datosVivienda?.calleVivienda}
                                disabled
                                required
                            />
                            <div className='polizasItem-container-data-item-data-list'>
                                <CustomTextInput
                                    label='NRO.'
                                    value={datosVivienda?.nroVivienda}
                                    disabled
                                    required
                                />
                                <CustomTextInput
                                    label='PISO'
                                    value={datosVivienda?.pisoVivienda}
                                    disabled
                                    required
                                />
                                <CustomTextInput
                                    label='DPTO.'
                                    value={datosVivienda?.dptoVivienda}
                                    disabled
                                    required
                                />  
                            </div>
                        </div>
                        <div className="polizasItem-container-data-item-map">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: googleApiKey }}
                                center={{
                                    lat: datosVivienda?.latitud,
                                    lng: datosVivienda?.longitud
                                }}
                                zoom={15}
                                options={{
                                    zoomControl: false,
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    rotateControl: false,
                                    fullscreenControl: false
                                }}
                            > 

                                <div 
                                    className="polizasItem-container-data-item-map-markerContainer"
                                    lat={datosVivienda?.latitud}
                                    lng={datosVivienda?.longitud}
                                >
                                    <span className="material-symbols-rounded" style={{ fontSize: 50, color: 'var(--primary500)' }}>location_on</span>
                                </div>
                            </GoogleMapReact>
                        </div>
                    </div>}
                </div>
            }
        </div>
    );

}

export default PolizasItem;