import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Checkouts.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useLocation } from 'react-router-dom';
import TableHeader from '../../components/TableHeader/TableHeader';

const Checkouts = (props: any) => {

    const location = useLocation();

    return (
        <div className='checkouts-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='checkouts-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'checkouts:download' }).length > 0}
                    data={{
                        createPath: '/checkouts/create',type: 'checkouts',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        subTitle: 'Datos de usuarios que se registraron pero abandonaron el sitio antes de finalizar el pago.',
                        createTitle: 'CREAR Actividad',
                        search: 'checkout_process',
                    }} canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'checkouts:create' }).length > 0}
                />
            </div>
        </div>
    );
}

export default Checkouts;