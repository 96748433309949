import React, { useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './SuperSellers.css'
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import TableHeader from '../../components/TableHeader/TableHeader';
import CrearInmobiliariaModal from '../../components/Modals/CrearInmobiliariaModal/CrearInmobiliariaModal';
import EditarInmobiliariaModal from '../../components/Modals/EditarInmobiliariaModal/EditarInmobiliariaModal';

const SuperSellers = (props: any) => {

    const [createModal, setCreateModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [editData, setEditData] = useState<any>(null)
    const location = useLocation();

    const EditarInmobiliariaAlert = () => {
        return (
            <Modal
                isOpen={editModal}
                onRequestClose={() => setEditModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '50%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <EditarInmobiliariaModal
                    onDismiss={() => setEditModal(false)}
                    editData={editData}
                />   
            </Modal>
        )
    }

    const CrearSucursalAlert = () => {
        return (
            <Modal
                isOpen={createModal}
                onRequestClose={() => setCreateModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '50%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <CrearInmobiliariaModal
                    onDismiss={() => setCreateModal(false)}
                />   
            </Modal>
        )
    }

    return (
        <div className='superSellers-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='superSellers-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'super_sellers:download' }).length > 0}
                    data={{
                        type: 'super_sellers',
                        search: 'v2/super_sellers',
                        createTitle: 'CREAR INMOBILIARIA',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        createPath: '/super_sellers/create'
                    }}
                    canCreateCustom={props.userData?.privileges?.filter(function(e: any) { return e === 'super_sellers:create' }).length > 0}
                    createTableItem={() => setCreateModal(true)}
                    canDelete={props.userData?.privileges?.filter(function(e: any) { return e === 'super_sellers:delete' }).length > 0}
                    canEditCustom={props.userData?.privileges?.filter(function(e: any) { return e === 'super_sellers:edit' }).length > 0}
                    editTableItem={() => setEditModal(true)}
                    setEditData={setEditData}
                    deleteData={{
                        title: '¿Confirmás que querés eliminar<br/>la siguiente inmobiliaria?',
                        subTitleSuccess: 'La inmobiliaria ha sido eliminada exitosamente.<br/>En el listado, podrás visualizarla como inactiva<br/>para no perder el registro histórico.',
                        data: "data[2] + '<br/>' + data[5]"
                    }}
                    deleteMultipleData={{
                        title: '¿Confirmás que querés eliminar<br/>las sucursales seleccionadas?',
                        subTitleSuccess: 'Las sucursales se eliminaron exitosamente.<br/>En el listado, podrás visualizarlas como inactivas <br/>para no perder el registro histórico.'
                    }}
                />
            </div>
            {CrearSucursalAlert()}
            {EditarInmobiliariaAlert()}
        </div>
    );
}

export default SuperSellers;